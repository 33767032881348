/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

.footnote,
.reversefootnote { 
	@apply px-1 font-semibold text-fiord-500 hover:text-tangerine-500 
}


.btn-primary {
	@apply bg-slate-800 text-white no-underline px-4 py-2 rounded;
}
